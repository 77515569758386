import React from 'react';
import 'animate.css';


import { NavLink } from 'react-router-dom';







function Home() {
   
        
    return (
        <div>
              
            <body class=" wow  animate__animated">
                <div className="  bg-contain  md:bg-cover opacity-95   lg:bg-fixed bg-no-repeat  pt-4 pb-10 md:pb-30 "
                    style={{ backgroundImage: `url(${'../img/bigstock-Executive-woman-working-on-ele-17007167.jpg'})` }}>
                    <div className=" container mx-auto px-4">

                        <div className="md:grid  grid-cols-2 grid-rows-1 lg:py-40 mt-56 md:text-white">
                            <div className="w-full ">

                            </div>
                            <div className="wow animate__animated animate__bounce animate__bounceInLeft  ">
                                <h1 className="lg:text-5xl  duration-300   text-4xl py-10 leading-2 cursor-pointer transform transition-transform hover:scale-110 ">Connecting Extraordinary Talent with Exceptional
                                    Opportunities</h1>
                                <p className="text-lg pt-5 duration-300  leading-2 cursor-pointer transform transition-transform hover:scale-110">Welcome to PRS, where we believe that talent is the driving force
                                    behind
                                    every successful
                                    organization. We are dedicated to unlocking the true potential of people and connecting
                                    them
                                    with companies that can leverage their unique skills and abilities. Our mission is to
                                    empower
                                    both job seekers and businesses by fostering meaningful connections that lead to
                                    long-term
                                    success.</p>
                                <div className="mt-5">
                 
                                <NavLink to="/Contactform" >
                                
   
     
                                        <button
                                            className=" border md:border-white border-black duration-300    hover:bg-black hover:text-white rounded-lg py-2 px-2">APPLY
                                            NOW</button>
                                            </NavLink>
                                                      </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- this is the 1 big div  --> */}

                <div className="lg:grid   lg:text-4xl grid-rows-1 grid-cols-2 lg2:px-32 lg3:px-72 px-5 my-10">
                    <div className=' wow animate__animated animate__bounce'>
                        <p className="py-4 leading-2 cursor-pointer transform transition-transform duration-300  hover:scale-110">Comprehensive Talent Solutions</p>
                        <div className="mt-6 lg:mt-40">
                        <NavLink to="/Contactform">
                                <button 
                                    className=" border border-black duration-300    hover:bg-black hover:text-white rounded-lg lg:py-2 py-1 px-4">Job-board
                                    →</button>
                                    </NavLink>
                        </div>
                    </div>
                    <div className="lg:grid animate__animated wow animate__backInLeft grid-cols-1  grid-rows-3">
                        <p className="py-3 pt-6 lg:-pt-2 duration-300   leading-2 cursor-pointer transform transition-transform hover:scale-110"><a href="">Data-Driven Insights →</a></p>
                        <p className="py-3 duration-300   leading-2 cursor-pointer transform transition-transform hover:scale-110"><a href="">Optimizing Recruitment Processes →</a></p>
                        <p className=" py-3 duration-300   leading-2 cursor-pointer transform transition-transform hover:scale-110"><a href="">Talent Pipeline Development →</a></p>
                    </div>

                </div>
                {/* <!-- this is the 2 big div  --> */}



                <div
                    className="text-center animate__backInDown lg:text-6xl text-3xl  mt-20 mb-5   leading-2 cursor-pointer transform transition-transform duration-300  hover:scale-110">
                    <h1>Your Gateway to Work!</h1>
                </div>
                {/* <!-- this is the 3 big div  --> */}

                <div className="bg-gray-800">
                    <div className="lg2:px-32 lg3:px-72 opacity-80  bg-cover bg-no-repeat  lg:pt-20 pt-60 lg:pb-40"
                        style={{ backgroundImage: `url(${('../img/photo-1615914143778-1a1a6e50c5dd.jpeg')})` }}>
                        <div className="lg:my-52 mx-40 py-5">
                        </div>
                        <div className="lg:pt-96 text-center wow animate__animated  animate__backInDown text-xl lg:text-4xl ">
                            <p className="text-white  py-10 mx-5    ">Discover a world of exciting job opportunities tailored to your skills
                                and
                                aspirations. Our platform
                                connects job seekers with top employers, offering a seamless and efficient hiring process. Whether
                                you're seeking temporary assignments, part-time gigs, or full-time careers</p>
                        </div>
                    </div>
                </div>
                {/* <!-- this is the 4 big div  --> */}
                <div className="py-10 lg2:px-32 lg3:px-72 bg-slate-100">

                    <div className="lg:grid grid-cols-2 grid-rows-1 px-6 ">
                        <div>
                            <h2 className="lg:text-4xl text-3xl text-center my-10">Building Talent Brands</h2>
                            <p className="lg:text-center my-10 lg:text-lg">A strong employer brand is crucial for attracting and
                                retaining
                                top
                                talent.
                                We work closely with our
                                clients to help them build and showcase their talent brands effectively. Through employer branding
                                strategies, we highlight your company's unique culture, values, and growth opportunities, making it
                                an attractive destination for skilled professionals.</p>
                        </div>
                        <div className=" pl-2 wow animate__animated animate__backInDown ">
                            <img className="w-full leading-2 cursor-pointer transform transition-transform duration-300  hover:scale-110" src="../img/istockphoto-925942192-170667a.jpg" alt="" />
                        </div>
                    </div>
                    <div className="lg:grid grid-cols-2 grid-rows-1  px-6 lg:my-20 ">
                        <h2 className="w-full text-center lg:hidden mb-10 mt-5 text-2xl lg:text-4xl">For Employers</h2>
                        <div className=" wow animate__animated animate__bounceInUp lg:py-32">
                            <img className="w-full leading-2 cursor-pointer transform transition-transform duration-300  hover:scale-110 " src="../img/istockphoto-1409365101-612x612.jpg" alt="" />
                        </div>
                        <div className="">
                            <h2 className="text-center  mb-10 mt-5 text-2xl lg:text-4xl">Empower Your Business with the Right People
                            </h2>
                            <p className="text-center text-lg">We understand that finding the right talent can transform a company. Our
                                recruitment experts are
                                adept at identifying top talent that not only possesses the required skills but also aligns with
                                your organization's culture and values. By streamlining the hiring process, we save you time and
                                resources, ensuring that you have the right people to drive your business forward.</p>
                            <h2 className="text-center my-10 text-2xl lg:text-4xl">Confidentiality and Trust</h2>
                            <p className="text-center text-lg lg:text-xl">we prioritize confidentiality and maintain the utmost
                                integrity in our
                                dealings with both job seekers and employers. You can trust that your sensitive information is safe
                                with us as we work diligently to create mutually beneficial connections.</p>
                            <div className="mt-20 ">
                            <NavLink to="/Contactform" >
                                    <button 
                                        className=" border border-black  duration-300   hover:bg-black hover:text-white rounded-lg py-1 lg:py-2 px-4">Submit
                                        your CV
                                        →</button>
                                    </NavLink> 
                         
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- this is the 5 big div  --> */}
                <div className="lg2:px-32 lg3:px-72 px-5">
                    <div>
                        <h2 className=" mt-20 text-2xl  lg:text-4xl  mb-5 leading-2 cursor-pointer transform transition-transform duration-300  hover:scale-110">Find a permanent job</h2>
                    </div>
                    <div className=" my-10 md:text-lg text-xs  divide-y ">
                        <div className="">
                            <div className=" grid  grid-cols-3 text-2xl grid-rows-1 ">
                                <div className="my-1 ">Job TItle</div>
                                <div className="my-1 ">Location</div>
                                <div className="my-1 ">Salary</div>

                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 gap-1 grid-rows-1">
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">Customer Service Assistant</div>
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">Wembley, Middlesex</div>
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">£24,750 + Pension + Bonus</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">Customer Support Consultant </div>
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">Brentford, Middlesex</div>
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">£30,000 + Pension + Parking</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">Aftersales Co-ordinator </div>
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">Brentford, Middlesex</div>
                                <div className="lg:animate__animated wow lg:animate__backInRight my-1 ">£27,500 + Pension + Parking</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Customer Service Executive</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Hounslow, Middlesex</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">£27,000 + Pension + Parking</div>
                            </div>
                        </div>
                    </div>
                    <div className="lg2:px-32 lg3:px-72 mt-20 ">
                        <NavLink to="/Contactform">
                            <button className=" border border-black duration-300    hover:bg-black hover:text-white rounded-lg py-3 px-4">Submit
                                your CV
                                →</button>
                                </NavLink>

                    </div>
                    <div>
                        <h2 className=" mt-20 text-2xl md:text-4xl  b-5 leading-2 cursor-pointer transform transition-transform duration-300  hover:scale-110">Immediate temporary jobs</h2>
                    </div>
                    <div className=" my-10 md:text-lg text-xs  divide-y ">
                        <div className="">
                            <div className=" grid  grid-cols-3 text-2xl grid-rows-1 ">
                                <div className="my-1  ">Job Title</div>
                                <div className="my-1  ">Location</div>
                                <div className="my-1  ">Salary</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Administrator</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Ealing</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">£14.00 per hour</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Customer Service Associate</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">London, W8</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">£28,000pa + Pension + Parking</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Accommodation Services Officer </div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Wembley, Middlesex</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">£17.00 per hour</div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 grid-rows-1">
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Welfare Officer</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">Wembley, Middlesex</div>
                                <div className="lg:animate__animated lg:wow lg:animate__backInRight my-1 ">£14.00 per hour</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- this is the 6 big div  --> */}
                <div className=" lg2:px-32 lg3:px-72 px-5 md:grid py-10  grid-rows-1 grid-flow-col grid-cols-3 ">
                    <div className=" col-span-2">
                        <h2 className="md:text-5xl text-xl px-4 text-center">Searching for short-term work opportunities?</h2>
                        <p className="text-center  text-sm md:text-xl mt-5   ">We've got you covered! Discover a wide range of exciting
                            temporary job
                            openings tailored to your skills
                            and preferences. Join our network of talented professionals and let us guide you through the process of
                            finding the perfect match. With our dedicated support and commitment to your success, you can trust us
                            to make your temporary work experience truly rewarding. Take the first step towards new possibilities
                            today and send us your CV!"</p>
                    </div>
                    <div className="h-70  w-50   ml-4  bg-slate-200 rounded-full  ">
                        <h2 className="md:text-5xl text-xl pt-10 md:pt-16 px-5 md:px-10 text-center">A trusted brand! Over the years
                        </h2>
                        <div className="text-center py-10 md:mt-20  ">
                        <NavLink to="/Contactform">
                                <button className="  duration-300     hover:bg-black hover:text-white rounded-2xl py-3 px-4">Submit
                                    your CV
                                    →</button>
                                    </NavLink>

                        </div>
                    </div>
                    
                </div>
                
            </body>
           
       
 
        </div>
       
    )
}

export default Home
