import React, { useState } from 'react';
import 'firebase/storage';
import ReCAPTCHA from 'react-google-recaptcha';


import emailjs from 'emailjs-com';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  // listAll,
  // list,
} from "firebase/storage";
import { storage } from "./firebase";


import { v4 } from "uuid";





function Contact() {
  

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaChange = () => {
    setIsCaptchaVerified(true);
  };


  
  const sendEmail = (e) => {
    e.preventDefault();
    if (isCaptchaVerified) {
      emailjs.sendForm('service_svvpj2l', 'template_fmydeym', e.target, 'Nb_0x6MjgYcQ0u1RQ')
      .then((result) => {
        if (!alert("Data Stored")) document.location = "/"
        alert('Email sent successfully:', result.text);
      })
      .catch((error) => {
        // alert("plz fill the data");
        console.error('Email error:', error);
      });
      // const uploadFile = () => {
        if (imageUpload == null) return;
        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
    
    
      // };
    } else {
      alert('Please verify reCAPTCHA.');
    }

    
  }



  return (
    <>
      <div className="lg:w-100 h-32 lg:h-48 bg-gray-800">
        <p className="text-white  text-7xl text-center lg:text-left pt-10 lg:pl-40 lg:pt-14">Contact</p>
      </div>

      {/* <h1 className="text-7xl lg:px-20 py-20 ">Contact</h1> */}

      <div className="bg-gray-800 text-white px-5 lg2:px-32 lg3:px-72">
        <div className="lg:grid grid-rows-1 lg:py-20 grid-cols-2">
          <div className=" py-20 lg:px-5  ">
            <p className="text-2xl  pb-20">For general questions, feedback, or inquiries about our services,
              please send an email to [General
              Email
              Address].
            </p>
            <div className="divide-y-2 text-xl  divide-white">
              <div className="flex lg:grid grid-rows-1  py-10  grid-cols-8">
                <div className=" w-24  "><img src="../img/619.png" alt="" /></div>
                <div className="col-span-6 pl-10">
                  <h2 className="text-2xl">OFFICE</h2>
                  <p>Havelock Hub 14 Havelock Place Harrow  HA1 1LJ

                  </p>
                </div>

              </div>
              <div className="flex  lg:grid grid-rows-1 py-10  grid-cols-8">
                <div className=" w-20  "><img src="../img/icons8-phone-100.png" alt="" /></div>
                <div className="col-span-6 pl-10">
                  <h2 className="text-2xl">Phone</h2>
                  <p>+44 3333 444 941</p>
                </div>


              </div>
              <div className="flex  lg:grid grid-rows-1 py-10  grid-cols-8">
                <div className=" w-20  "><img src="../img/icons8-email-96.png" alt="" /></div>
                <div className="col-span-6 pl-10">
                  <h2 className="text-2xl">Email</h2>
                  <p>inquiry@prs-recruitment.com</p>
                </div>


              </div>

            </div>
          </div>
          <div className=" ">
            <iframe className='w-full  hidden md:block h-800'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11792.721607399446!2d-0.3494432836972585!3d51.58446793655346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487613be81525695%3A0xa6f223a4a9be4cf0!2sHavelock%20Hub%20Serviced%20Offices!5e0!3m2!1sen!2sin!4v1691823145861!5m2!1sen!2sin"
              height="800"
              referrerPolicy="no-referrer-when-downgrade" />
          </div>
          <div className=" md:hidden m-auto w-full pb-5">
            <iframe className='w-full '
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11792.721607399446!2d-0.3494432836972585!3d51.58446793655346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487613be81525695%3A0xa6f223a4a9be4cf0!2sHavelock%20Hub%20Serviced%20Offices!5e0!3m2!1sen!2sin!4v1691823145861!5m2!1sen!2sin"
              height="500"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        {/* style="border:0;" allowfullscreen="" loading="lazy" */}
      </div>
      <div className="grid lg:grid-cols-2  mt-20 px-5 lg2:px-32 lg3:px-72">
        <div className="col-span-1">
          <h4 className="">READY TO WORK WITH US?</h4>
          <p className="py-10 duration-300 hover:scale-110  lg:py-20 lg:font-extrabold text-2xl lg:text-5xl lg:leading-normal">"If you're prepared to take a leap towards achieving greater success, we're eager to start a conversation."</p>
          <p className="pr-5    "> If you are a job seeker looking for exciting opportunities, our candidate support
            team is here to
            help. You can contact them at Email to explore available job
            openings and
            guidance on your job search. </p>

          <ul className="py-8  lg:px-16">

            <li> ✔&nbsp;Connecting top-tier talent with industry-leading companies for mutual success.</li>
            <li> ✔&nbsp;Empowering businesses with exceptional talent acquisition solutions.</li>
            <li> ✔&nbsp;Driving growth through strategic recruitment of exceptional professionals.</li>
            <li> ✔&nbsp;Crafting future success stories by matching talent with opportunity.</li>
            <li> ✔&nbsp;Elevating organizations through precision recruitment and staffing.</li>
          </ul>
        </div>
        <form enctype="multipart/form-data" method="post" onSubmit={sendEmail} action="" className=" lg:-mt-16 bg-white lg:px-10 py-10">
        <div className="lg:grid gap-2 grid-cols-2">
            <div className="col-span-1 ">
              <label>First Name</label>
              <input type="text" required  name='user_last_name'placeholder="Enter your First Name" className="border  rounded-lg p-2 mt-2 mb-2 w-full" /><br />
            </div>

            <div className="col-span-1 px-2">
              <label className=" ">Last Name</label>
              <input type="text" name='user_last_name'placeholder="Enter your Last Name" className="border  rounded-lg  p-2 mt-2 mb-2 px-5 w-full" /><br />
            </div>
          </div>

                        

                        <div className="mt-8">
                            <label className="">Email</label><br />
                            <input type="email" required name='user_email' className="border    rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Enter your Email" /><br />
                        </div>
                        <div className="mt-8">
                            <label className="">Phone Number</label><br />
                            <input type="number" required name='user_phone_number' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Enter your Phone Number" /><br />
                        </div>

                        <div className="mt-8">
                            <label>Service Description</label><br />
                            <input name='user_service_description' required className="border   rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Service Description" /><br />
                        </div>

                        <div className="mt-8">
                            <label>Comment or Message</label><br />
                            <textarea row="10" required name='message' placeholder='Message' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" ></textarea>
                        </div>

                        <div className="App">
                            <input
                                type="file"
                                onChange={(event) => {
                                    setImageUpload(event.target.files[0]);
                                }}
                            />
                        </div>
                       
                        <div className=" my-14 ">
                            <ReCAPTCHA sitekey="6LdBo5YnAAAAAIa_nmquSmJH_qV_F20uy6LEwka0" onChange={handleCaptchaChange} />

                            <a href="" target="_blank">
                                <button
                                    className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                                    QUOTE</button>
                            </a>
                        </div>
                    </form>
      
        



      </div>
    </>
  )
}

export default Contact;
