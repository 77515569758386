import React from 'react'
import { NavLink } from 'react-router-dom';


function Job() {
    return (
        <div>
            <body className="font-serif  ">
                {/* <!-- this is header --> */}
                <div className="lg:w-100 h-32 lg:h-48 bg-gray-800">
                    <p className="text-white lg:text-7xl text-7xl text-center lg:text-left pt-10 lg:pl-40 lg:pt-14">Job Board</p>
                </div>
                
        
                {/* <!-- this is big div 1 --> */}
                <div className=" lg:grid grid-rows-1 grid-cols-2 lg2:px-32 lg3:px-72 px-5 py-20 lg:divide-x-2 lg:divide-y-2">
                    <div className="lg:text-6xl text-5xl px-5 col-span-1">
                        <h2>The future belongs to those who believe in the beauty of their dreams</h2>
                    </div>
                    <div className=" lg:text-lg lg:pt-10 pt-2 lg:px-20 px-5 col-span-1 ">
                        <div
                        >Our mission is to help you secure a rewarding career by making the most of the opportunities available
                            today. By understanding your skills, aspirations, and potential, we connect you with employers who share
                            your vision for a brighter tomorrow. Let's build a successful future together, starting with the
                            opportunities of today. Join us and pave the way for a fulfilling career at PRS.</div>
                    </div>
                </div>
                <div>

                    <div className="flex justify-center">
                        <img className=" flex rounded-full w-70   h-40  bg-red-300 bg-cover bg-no-repeat leading-2 cursor-pointer transform transition-transform hover:scale-110"
                            src="../img/elegant-businesswoman-standing-in-office-with-digital-tablet.jpg" alt="" />
                    </div>

                    <div className="lg:text-center text-center lg:text-3xl text-xl lg2:px-32 lg3:px-72 px-14 py-10 lg:my-20 ">
                        <div>“We are dedicated to unlocking the true potential of people and connecting them with companies that can
                            leverage their unique skills and abilities.”</div>
                    </div>
                </div>
                <div className="lg:my-10 my-8 lg2:px-32 lg3:px-72  mx-8 divide-y-2 ">
                    <h2 className="lg:text-6xl text-2xl my-10 lg:text-center">Services We Provide</h2>
                    <div className="lg:flex lg:my-10 my-4 flex-row ">
                        <div className="lg:my-10">
                            <h2 className="text-xl my-10 ">01.</h2>
                            <h2 className="text-4xl my-10 ">Temporary Staffing</h2>
                            <div className="lg:pr-10 ">Matching job seekers with short-term or seasonal positions to meet the immediate
                                staffing needs of
                                businesses. This can be beneficial for companies dealing with fluctuations in workload or for
                                covering employee leaves.</div>
                        </div>
                        <div className="lg:my-10">
                            <h2 className="text-xl my-10 ">02.</h2>
                            <h2 className="text-4xl lg:my-10 my-4 ">Permanent Staffing</h2>
                            <div className="lg:pr-10">
                                Identifying and recruiting candidates for long-term employment, helping businesses find the right
                                fit for permanent positions within their organization.
                            </div>
                        </div>
                        <div className="lg:my-10">
                            <h2 className="text-xl my-10 ">03.</h2>
                            <h2 className="text-4xl my-10 ">Temporary-to-Permanent Placement</h2>
                            <div className="lg:pr-10">
                                Providing a trial period for candidates in temporary roles, giving employers an opportunity to
                                assess their performance before making a permanent hiring decision.
                            </div>
                        </div>
                        <div className="lg:my-10">
                            <h2 className="text-xl my-10 ">04.</h2>
                            <h2 className="text-4xl my-10 ">Contract Staffing</h2>
                            <div className="lg:pr-10">
                                Supplying skilled professionals on a contractual basis to fulfill specific project needs or
                                short-term assignments.
                            </div>
                        </div>
                    </div>
                    <div className="lg:flex lg:my-10 flex-row ">
                        <div className="my-10">
                            <h2 className="text-xl my-10 ">05.</h2>
                            <h2 className="text-4xl my-10 ">Executive Search</h2>
                            <div className="pr-10">
                                Specialized recruitment services focused on finding top-level executives and leaders for key
                                positions in organizations.
                            </div>
                        </div>
                        <div className="my-10">
                            <h2 className="text-xl my-10 ">06.</h2>
                            <h2 className="text-4xl my-10 ">Contract Staffing</h2>
                            <div className="pr-10">
                                Supplying skilled professionals on a contractual basis to fulfill specific project needs or
                                short-term assignments.
                            </div>
                        </div>
                        <div className="my-10">
                            <h2 className="text-xl my-10 ">07.</h2>
                            <h2 className="text-4xl my-10 ">Industry-Specific Recruitment</h2>
                            <div className="pr-10">
                                Some staffing companies may specialize in specific industries, such as healthcare, technology,
                                finance, etc., tailoring their services to cater to the unique requirements of those sectors.
                            </div>
                        </div>
                        <div className="my-10">
                            <h2 className="text-xl my-10 ">08.</h2>
                            <h2 className="text-4xl my-10 ">Consulting Services</h2>
                            <div className="pr-10">
                                Providing expert advice and guidance on talent acquisition strategies, workforce planning, and other
                                HR-related matters.
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- <div className="bg-cover bg-center bg-opacity-10 h-32 w-32 bg-gradient-to-br from-blue-500 to-purple-500"> --> */}
                <div className="bg-black">

                    <div className="bg-cover bg-no-repeat  lg2:px-32 lg3:px-72 px-10 py-20  " style={{
                        backgroundImage: `url(${('../img/elegant-businesswoman-standing-in-office-with-digital-tablet.jpg')})`
                    }}>


                        <h2 className="lg:text-6xl text-3xl text-center my-32">Main Services</h2>

                        <div className="border border-black mx-1">
                            <div className="lg:grid grid-cols-4 lg:divide-x-2 lg:divide-black grid-rows-1 ">
                                <div className=" mx-2">
                                    <h2 className="text-xl lg:my-10 ">01.</h2>
                                    <h2 className="text-4xl my-10">Tailored Recruitment Solutions</h2>
                                    <div className="text-lg lg:my-10">
                                        Our staffing agency provides personalized recruitment solutions to meet the unique needs of
                                        your business. From understanding your company culture to identifying specific skill
                                        requirements, we ensure a perfect match between candidates and your organization.
                                    </div>
                                    <div className=" my-8 ">
                                    <NavLink to="/Contactform">
                                            <button
                                                className=" border border-black   leading-2 cursor-pointer transform transition-transform hover:scale-110  hover:bg-black hover:text-white rounded-2xl py-3 px-4">Submit
                                                your CV
                                                →</button>
                                                </NavLink> 
                                                                                    </div>
                                </div>
                                <div className=" px-2">
                                    <h2 className="text-xl my-10 ">02.</h2>
                                    <h2 className="text-4xl my-10">Vast Talent Network</h2>
                                    <div className="text-lg my-10">
                                        With access to an extensive talent pool, we can source top-notch candidates across various
                                        industries and job roles. Our wide network enables us to find the right talent quickly and
                                        efficiently, saving you time and effort.
                                    </div>
                                    <div className=" mt-8 ">
                                    <NavLink to="/Contactform">
                                            <button
                                                className=" border border-black   leading-2 cursor-pointer transform transition-transform hover:scale-110  hover:bg-black hover:text-white rounded-2xl py-3 px-4">Submit
                                                your CV
                                                →</button>
                                                </NavLink> 
                                                                                    </div>
                                </div>
                                <div className=" px-2">
                                    <h2 className="text-xl my-10 ">03.</h2>
                                    <h2 className="text-4xl my-10">Temporary and Permanent Placements</h2>
                                    <div className="text-lg my-10">
                                        Whether you need short-term assistance or are looking for a long-term addition to your team,
                                        our staffing services cover both temporary and permanent placements. We cater to your
                                        workforce needs in a flexible and seamless manner.
                                    </div>
                                    <div className=" mt-8 ">
                                    <NavLink to="/Contactform">

                                            <button
                                                className=" border border-black  leading-2 cursor-pointer transform transition-transform hover:scale-110   hover:bg-black hover:text-white rounded-2xl py-3 px-4">Submit
                                                your CV
                                                →</button>
                                                </NavLink> 


                                    </div>
                                </div>
                                <div className=" px-2">
                                    <h2 className="text-xl my-10 ">04.</h2>
                                    <h2 className="text-4xl my-10">Expert Screening and Evaluation</h2>
                                    <div className="text-lg my-10">
                                        Our thorough screening process ensures that only the most qualified candidates reach your
                                        interview stage. We assess skills, experience, and cultural fit to present you with
                                        candidates who align with your company’s vision and goals.
                                    </div>
                                    <div className=" my-8 ">
                                    <NavLink to="/Contactform">
                                            <button className=" border border-black  leading-2 cursor-pointer transform transition-transform hover:scale-110   hover:bg-black hover:text-white rounded-2xl py-3 px-4">Submit
                                                your CV
                                                →</button>
                                                </NavLink>                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </body>

        </div>
    )
}

export default Job
