// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCdDhv2UWvuUORFXJ32q70Lw0cqcbHwwdg",
  authDomain: "my-data-cb158.firebaseapp.com",
  projectId: "my-data-cb158",
  storageBucket: "my-data-cb158.appspot.com",
  messagingSenderId: "807740051259",
  appId: "1:807740051259:web:f7710fb26a84388a5d711e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const  storage = getStorage(app);
// export const database = getDatabase(app);

