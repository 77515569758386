import React from 'react';

const About = () => {
  return (
    <>
    <div className="lg:w-100 h-32 lg:h-48 bg-gray-800">
        <p className="text-white  text-7xl text-center  lg:text-left pt-10 lg:pl-40 px-14 lg:pt-14">ABOUT</p>
      </div>
      
      <div className="lg2:px-32 lg3:px-72 lg:-mt-52 px-5 py-20 lg:grid grid-rows-1 grid-flow-col gap-10 grid-cols-2 ">
        <div className=" lg:text-5xl text-2xl font-bold lg:pt-36 pt-0 ">
          <h2 className="transform">Trusted by Prominent Organizations and Committed to Empowering Local Talent</h2>
        </div>
        <div className="w-full pt-6 lg:pt-0 "><img className=" leading-2 cursor-pointer transform transition-transform hover:scale-110"
          src="../img/portrait-of-man-working-remotely-on-laptop-and-looking-at-camera-BSZF00886.jpg" alt="" />

        </div>

      </div>
      <div className="lg2:px-32 lg3:px-72 px-5 lg:text-2xl text-lg lg:text-justify">
        We collaborate closely with local businesses, recognizing the value of a strong symbiotic
        relationship between talent and employers. By thoroughly understanding the needs and culture of each
        organization, we can recommend the ideal candidates who will not only contribute to their success
        but also thrive in their roles<br />
        <br />
        They value our experience in the market, our knowledge of the local area our integrity and strong
        business ethics along with the special attention they always receive from us.<br />
        <br />
        Conveniently located in Harrow with dedicated, long serving, stable and experienced staff, we are
        easily accessible and always available.
      </div>
      <div className="lg:grid grid-cols-2 grid-rows-1 lg2:px-32 lg3:px-72 lg:py-32">
        <div className="px-8 py-8 leading-2 cursor-pointer transform transition-transform hover:scale-110"><img src="../img/pexels-photo-4050290.jpeg" alt="" /></div>
        <div className=" text-3xl font-bold  px-10 pb-14 lg:y-20 ">
          <div className="pt-14 pb-4 "><img className="" src="../img/QuotesIconFinal.png" alt="" /></div>
          <div >
            The whole secret of a successful life is to find out what is one's destiny to do, and then do it.
          </div>
        </div>
      </div>
      <div className="lg:my-10 my-8 lg2:px-32 lg3:px-72 px-5 divide-y-2 ">
        <h2 className="text-5xl  my-10 lg:text-center">Services We Provide</h2>
        <div className="lg:flex lg:my-10 my-4 flex-row  ">
          <div className="lg:my-10">
            <h2 className="text-xl my-10 ">01.</h2>
            <h2 className="text-4xl my-10 ">Temporary Staffing</h2>
            <div className="lg:pr-14 ">Matching job seekers with short-term or seasonal positions to meet the immediate
              staffing needs of
              businesses. This can be beneficial for companies dealing with fluctuations in workload or for
              covering employee leaves.</div>
          </div>
          <div className="lg:my-10">
            <h2 className="text-xl my-10">02.</h2>
            <h2 className="text-4xl lg:my-10 my-4 ">Permanent Staffing</h2>
            <div className="lg:pr-10">
              Identifying and recruiting candidates for long-term employment, helping businesses find the right
              fit for permanent positions within their organization.
            </div>
          </div>
          <div className="lg:my-10">
            <h2 className="text-xl my-10 ">03.</h2>
            <h2 className="text-4xl my-10 ">Temporary-to-Permanent Placement</h2>
            <div className="lg:pr-10">
              Providing a trial period for candidates in temporary roles, giving employers an opportunity to
              assess their performance before making a permanent hiring decision.
            </div>
          </div>
          <div className="lg:my-10">
            <h2 className="text-xl my-10 ">04.</h2>
            <h2 className="text-4xl my-10 ">Contract Staffing</h2>
            <div className="lg:pr-10">
              Supplying skilled professionals on a contractual basis to fulfill specific project needs or
              short-term assignments.
            </div>
          </div>
        </div>
        <div className="lg:flex lg:my-10 my-4 flex-row  ">

          <div className="my-10">
            <h2 className="text-xl my-10 ">05.</h2>
            <h2 className="text-4xl my-10 ">Executive Search</h2>
            <div className="pr-10">
              Specialized recruitment services focused on finding top-level executives and leaders for key
              positions in organizations.
            </div>
          </div>
          <div className="my-10">
            <h2 className="text-xl my-10 ">06.</h2>
            <h2 className="text-4xl my-10 ">Contract Staffing</h2>
            <div className="pr-10">
              Supplying skilled professionals on a contractual basis to fulfill specific project needs or
              short-term assignments.
            </div>
          </div>
          <div className="my-10">
            <h2 className="text-xl my-10 ">07.</h2>
            <h2 className="text-4xl my-10 ">Industry-Specific Recruitment</h2>
            <div className="pr-10">
              Some staffing companies may specialize in specific industries, such as healthcare, technology,
              finance, etc., tailoring their services to cater to the unique requirements of those sectors.
            </div>
          </div>
          <div className="my-10">
            <h2 className="text-xl my-10 ">08.</h2>
            <h2 className="text-4xl my-10 ">Consulting Services</h2>
            <div className="pr-10">
              Providing expert advice and guidance on talent acquisition strategies, workforce planning, and other
              HR-related matters.
            </div>
          </div>
        </div>
      </div>
      {/* </body> */}

    </>
  );
};

export default About;



