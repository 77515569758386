import React from 'react'
import { NavLink } from 'react-router-dom';


function Candidate() {
  return (
    <div className="font-serif">

      <div className="lg:w-100 h-32 lg:h-48 bg-gray-800">
        <p className="text-white text-7xl text-center lg:text-left pt-10 lg:pl-40 lg:pt-14">Candidates</p>
      </div>
      <div>
        <p className="text-black text-4xl  lg:pl-96 pt-6 ml-10 ">Comprehensive Talent Solutions</p>
      </div>
      <div className="lg:grid lg2:px-32 lg3:px-72 px-5 lg:grid-cols-3 grid-rows-2">
        <p className=" col-span-1 pt-10 row-span-2"><img className="lg:w-96 w-full lg:rounded-full leading-2 cursor-pointer transform transition-transform hover:scale-110"
          src="../img/Recruitment-Process-Optimization.png" alt=""/>
        </p>
        <p className="pt-8 col-span-2  lg:text-lg text-left">Welcome to our company, where we offer Comprehensive Talent Solutions
          tailored
          to
          empower your organization’s growth and success. As a leading provider of talent acquisition and
          workforce
          optimization services, we understand that the right people are the driving force behind any thriving
          business. Our dedicated team of experts is committed to partnering with you to identify, attract, and
          retain
          top-tier talent that aligns seamlessly with your company’s culture and objectives.</p>

        <p className="pt-4 text-left lg:text-lg col-span-2">From temporary staffing solutions to permanent placements and executive
          search, we have a wide array of
          services designed to meet your unique needs. Our industry-specific recruitment expertise ensures that we
          grasp the nuances of your sector, allowing us to source candidates who possess the specialized skills
          and
          experience required to excel in their roles.</p>
      </div>
      <div className="text-center mt-20 ">
        <NavLink to="/Contactform" >
          <button className="border border-black duration-300    lg:ml-28  mt-10 lg:mt-24  hover:bg-black hover:text-white rounded-lg py-3 px-4">Submit
            your CV
          </button>
        </NavLink>
      </div>
      <div className=" pt-8 ">
        <p className="text-3xl text-center">Data-Driven Insights</p><br/>
      </div>
      <div className="lg2:px-32 lg3:px-72 px-5">
        <p className="lg:text-justify text:left"><span className="">We believe in harnessing the power of data to unlock valuable and
          actionable information for our clients. Our
          team of skilled analysts and data scientists diligently work to</span> <span className="">transform
            raw data into strategic
            intelligence, providing a clear vision of market trends, customer behaviors, and business opportunities.
            With a focus on precision and accuracy,</span> we delve deep into data analytics to help businesses
          make informed
          decisions, optimize processes, and stay ahead of the competition. Trust our data-driven approach to
          navigate
          <span className=""> the complexities of today’s dynamic business landscape, and let us be your strategic
            partner in achieving
            sustainable growth and achieving your goals.</span>
        </p>
      </div>

      <div className="  lg:grid grid-cols-2 px-5 grid-rows-1 lg2:px-32 lg3:px-72 ">

        <div className="px-2">
          <img className=" lg:mt-52 mt-8 lg:hidden w-full  " src="../img/office.jpeg" alt=""/>
            <h2 className=" lg:mt-14 text-4xl mt-10 ">Optimizing Recruitment Processes</h2>
            <p className=" mt-8 ">This is essential for businesses aiming to attract and secure top talent
              efficiently. By streamlining and fine-tuning each stage of the recruitment journey, organizations can
              save
              time, reduce costs, and enhance the overall quality of hires. Employing advanced applicant tracking
              systems,
              artificial intelligence, and automation can significantly improve the screening and shortlisting
              process,
              allowing recruiters to focus on evaluating the best-fit candidates. Additionally, refining job
              descriptions,
              enhancing employer branding, and utilizing targeted sourcing strategies can attract a more relevant and
              diverse talent pool. Through continuous analysis and feedback, recruitment processes can be continually
              refined, leading to better outcomes, higher employee retention, and a competitive edge in today’s
              dynamic
              job market.</p>
              <NavLink to="/Contactform" 
                                >
              <button className="  border border-black duration-300    lg:ml-28  mt-10 lg:mt-24  hover:bg-black hover:text-white rounded-lg py-3 px-4">Submit
                your CV
              </button>
            </NavLink>
        </div>
        <div className="">
          <img className=" lg:mt-52 mt-8  w-full lg:ml-12 leading-2 cursor-pointer transform transition-transform hover:scale-110" src="../img/4344860.jpg" alt=""/>
        </div>
      </div>
      <div className="my-20 lg2:px-32 lg3:px-72 px-5">
        <h2 className="mt-4  text-4xl ">Talent Pipeline Development</h2>
        <p className="mt-4 ">we nurture a continuous flow of exceptional talent to meet your organization’s evolving needs.
          We understand
          that building a strong workforce is the cornerstone of success in today’s dynamic business landscape.
          Our
          innovative approach focuses on identifying and cultivating promising candidates, ensuring a steady supply of
          skilled professionals ready to step into critical roles.</p>

        <p className="mt-4 ">Through strategic partnerships with educational institutions, industry associations, and job
          boards, we
          proactively source and attract top-tier talent aligned with your company’s values and objectives. Our
          comprehensive screening and assessment processes rigorously evaluate candidates’ competencies, cultural fit,
          and potential for growth. By fostering long-term relationships with prospective employees, we create a
          robust Talent Pipeline that not only fulfills immediate staffing requirements but also aligns with your
          organization’s long-term vision.</p>
      </div>

    </div>
  )
}

export default Candidate
