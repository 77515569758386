import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Home from "./Component/Home";
import About from "./Component/About";
import Contact from "./Component/Contact";
import Candidate from "./Component/Candidate";
import Job from "./Component/Job";
import Contactform from "./Component/Contactform";
import "wow.js/css/libs/animate.css"; // Import wow.js CSS
import WOW from "wow.js"; // Import wow.js library

import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Terms from "./Component/Terms";
// // import firebase from 'firebase/app';
// import 'firebase/storage';

const App = () => {
  const wow = new WOW();
  wow.init();

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
      {/* <LoadingBar/> */}
      <div className="">
        <div className=" lg:py-4  bg-local bg-slate-950 ">
          <div className="container mx-auto lg:px-4">
            <nav className="flex items-center justify-between">
              {/* <!-- Brand/logo --> */}
              <img
                className="hidden mobile-menu lg:flex  w-52 "
                src="../img/PRS_LOGO.png"
                alt=""
              />

              <div className="w-full lg:hidden grid  ">
                <div className="flex  py-10 ">
                  <img
                    className=" w-32 mx-auto "
                    src="../img/PRS_LOGO.png"
                    alt=""
                  />
                  <div onClick={handleNav} className=" px-2  lg:hidden">
                    {nav ? (
                      <AiOutlineClose size={40} className=" text-white" />
                    ) : (
                      <AiOutlineMenu size={40} className=" text-white" />
                    )}
                  </div>
                </div>
                <ul
                  className={
                    nav
                      ? "   text-center text-4xl  ease-in-out duration-500"
                      : "ease-in-out duration-500 fixed left-[-100%]"
                  }
                >
                  <li className=" py-5">
                    <Link
                      to="/"
                      className="text-white  cursor-pointer transform transition-transform hover:scale-110 "
                    >
                      Home
                    </Link>
                  </li>
                  <li className=" py-5">
                    <Link to="/about" className="text-white  ">
                      About
                    </Link>
                  </li>
                  <li className=" py-5">
                    <Link to="/contact" className="text-white  ">
                      Contact
                    </Link>
                  </li>
                  <li className=" py-5">
                    <Link to="/Candidate" className="text-white">
                      Candidate
                    </Link>
                  </li>
                  <li className=" py-5">
                    <Link to="/job" className="text-white">
                      Job
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="hidden mobile-menu  lg:flex space-x-4">
                <li>
                  <Link
                    to="/#"
                    className="text-white leading-2 cursor-pointer transform transition-transform hover:scale-110"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="text-white leading-2 cursor-pointer transform transition-transform hover:scale-110"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="text-white leading-2 cursor-pointer transform transition-transform hover:scale-110"
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <NavLink
                    to="/Candidate"
                    className="text-white leading-2 cursor-pointer transform transition-transform hover:scale-110"
                  >
                    Candidate
                  </NavLink>
                </li>
                <li>
                  <Link
                    to="/job"
                    className="text-white leading-2 cursor-pointer transform transition-transform hover:scale-110"
                  >
                    Job
                  </Link>
                </li>
              </ul>

              <div className="hidden mobile-menu lg:flex space-x-4">
                <NavLink to="/Contactform">
                  <button className=" border border-white  text-white  hover:bg-white hover:text-black rounded-lg py-2 px-2">
                    APPLY NOW
                  </button>
                </NavLink>
              </div>
              <div className=" w-28 py-5 gap-8  grid-cols-3  hidden lg:grid">
                <a href="#!" role="button">
                  {/* <!-- Facebook --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    style={{ color: "#1877f2" }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </a>

                <a href="#!" role="button">
                  {/* <!-- Instagram --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    style={{ color: "#c13584" }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a href="#!" role="button">
                  {/* <!-- Twitter --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    style={{ color: "#1da1f2" }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
              </div>
            </nav>
          </div>
        </div>
        <hr />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/candidate" element={<Candidate />} />
          <Route path="/job" element={<Job />} />
          <Route path="/Contactform" element={<Contactform />} />
          <Route path="/Terms" element={<Terms />} />

          {/* <Route path="/job" component= {< Job />}/> */}
        </Routes>

        <div className=" bg-slate-200 lg:divide-y-2 ">
          <div className="lg:grid grid-rows-1 lg:px-40 px-10  py-10 grid-cols-4">
            <div className="">
              <img
                className="w-52 px-5  text-center  py-10 leading-2 cursor-pointer transform transition-transform hover:scale-110 "
                src="../img/Final Logo 7-8-23.jpg"
                alt=""
              />
              <p className="lg:py-5 px-5">
                Choose a job you love, and you will never have to work a day in
                your life.
              </p>
              <div className="grid  ml-5  gap-8 w-28 py-5  grid-cols-3">
                <a href="#!" role="button">
                  {/* <!-- Facebook --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110  h-8 w-8"
                    fill="currentColor"
                    style={{ color: "#1877f2" }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </a>

                <a href="#!" role="button">
                  {/* <!-- Instagram --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    style={{ color: "#c13584" }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a href="#!" role="button">
                  {/* <!-- Twitter --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    style={{ color: "#1da1f2" }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="px-5">
              <h2 className="py-10 text-2xl font-bold">Services</h2>
              <ul>
                <li>
                  <Link to="/job" className="">
                    Temporary Staffing
                  </Link>
                </li>
                <li>
                  <Link to="/job" className="">
                    Permanent Staffing
                  </Link>
                </li>
                <li>
                  <Link to="/job" className="">
                    Temporary-to-Permanent Placement
                  </Link>
                </li>
                <li>
                  <Link to="/job" className="">
                    Contract Staffing
                  </Link>
                </li>
              </ul>
            </div>
            <div className="px-5">
              <h2 className="py-10  text-2xl font-bold">Quick Links</h2>

              <ul>
                <li>
                  <Link to="/" className="">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/Candidate" className="">
                    Candidate
                  </Link>
                </li>
                <li>
                  <Link to="/job" className="">
                    Job
                  </Link>
                </li>
                <li>
                  <Link to="/Terms" className="">
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="px-5">
              <h2 className="py-10  text-2xl font-bold">Contact Info</h2>
              <ul>
                <li>
                  <Link to="/contact" className="">
                    Havelock Hub 14 Havelock Place Harrow HA1 1LJ
                  </Link>
                </li>
                {/* <li >
                  <Link to="/contact" className="">mail@example.com</Link>
                </li> */}
                <li>
                  <Link to="/contact" className="">
                    +44 3333 444 941
                  </Link>
                </li>
                <li className="my-2 text-xl font-semibold text-blue-800  hover:scale-105 transition transform hover:ease-in-out">
                  <a
                    href="https://prs-recruitment.app.aertemp.com"
                    className=" text-xl font-semibold underline "
                  >
                    <button className="border-2 border-black rounded-lg p-2 hover:bg-black hover:text-white">
                      Download our application
                    </button>
                  </a>
                </li>
              </ul>
              <div className="my-5 lg:flex ">
                <div>
                  <img
                    className=" mobile-menu sm:flex w-32 lg:mx-5"
                    src="../img/google play AER Temp.png"
                    alt=""
                  />
                  <a href="https://play.google.com/store/apps/details?id=com.claritylocums.aerTemp&hl=en_IE&gl=US">
                    <img
                      className=" mobile-menu sm:flex  w-32 my-2 lg:mx-4"
                      src="../img/Google_play.png"
                    />
                  </a>
                  {/* <a href="https://play.google.com/store/apps/details?id=com.claritylocums.aerTemp&hl=en_IE&gl=US">
                    <p className="bg-slate-300 p-1 rounded mx-1 my-3 relative right-5 text-center font-bold text-blue-800">
                      Get it on GOOGLE PLAY
                    </p>
                  </a> */}
                </div>
                <div className="lg:mx-5">
                  <img
                    className=" mobile-menu xl:flex  w-32  lg:mx-5 "
                    src="../img/ios app store AER Temp.png"
                    alt=""
                  />
                  <a href="https://apps.apple.com/ie/app/aer-temp/id6443434240">
                    <img
                      className=" mobile-menu xl:flex  w-32 my-2 lg:mx-4"
                      src="../img/app_store.png"
                    />
                  </a>
                  {/* <a href="https://apps.apple.com/ie/app/aer-temp/id6443434240">
                    <p className="bg-slate-300 p-1 rounded mx-1 my-3 relative -left-2 text-center font-bold text-blue-800">
                      Availabe on the AppStore
                    </p>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className=" lg:grid grid-cols-2  py-5 grid-rows-1">
            <div className="lg:ml-72 text-center ">
              <p>Copyright © 2023 PRS Recruitment Company</p>
            </div>
            <div className="lg:mr-72 text-center">
              <a href="https://jkinfosysglobal.com/" target="_blank">
                <p>Powered By JK Infosys Global</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
