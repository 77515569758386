// import React from 'react'
import React, { useState } from 'react';
import 'firebase/storage';
import ReCAPTCHA from 'react-google-recaptcha';

import emailjs from 'emailjs-com';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  // listAll,
  // list,
} from "firebase/storage";
import { storage } from "./firebase";

import { v4 } from "uuid";




const Contactform = () => {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaChange = () => {
    setIsCaptchaVerified(true);
  };


  
  const sendEmail = (e) => {
    e.preventDefault();
    if (isCaptchaVerified) {
      emailjs.sendForm('service_svvpj2l', 'template_fmydeym', e.target, 'Nb_0x6MjgYcQ0u1RQ')
      .then((result) => {
        if (!alert("Data Stored")) document.location = "/"
        alert('Email sent successfully:', result.text);
      })
      .catch((error) => {
        alert("plz fill the data");
        console.error('Email error:', error);
      });
      // const uploadFile = () => {
        if (imageUpload == null) return;
        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
    
    
      // };
    } else {
      alert('Please verify reCAPTCHA.');
    }

    
  }



  return (
    <div className=' bg-slate-200'>
       <div className="lg:w-100 h-60 bg-gray-800">
        <p className="text-white  text-7xl text-center lg:text-left pt-10 lg:pl-40 lg:pt-14">Submit your CV</p>
      </div>

      <div class="container  mx-auto md:px-6">
     
         
        <section class="">
          <div class="container px-6 md:px-12">
            <div
              class="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  dark:shadow-black/20 md:py-16 md:px-12 -mt-[50px] backdrop-blur-[30px]">
              <div class="flex flex-wrap">
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 ">
                  <form enctype="multipart/form-data" method="post" onSubmit={sendEmail} >
                  <div className="lg:grid gap-2 grid-cols-2">
                      <div className="col-span-1 px-2">
                        <label>First Name</label>
                        <input type="text" required name='user_last_name' className="border   rounded-lg p-2 mt-2 mb-2 w-full" /><br />
                      </div>



                      <div className="col-span-1 px-2">
                        <label className=" ">Last Name</label>
                        <input type="text"  name='user_last_name' className="border   rounded-lg  p-2 mt-2 mb-2 px-5 w-full" /><br />
                      </div>
                    </div>

                    <div className="mt-8">
                      <label className="">Email</label><br />
                      <input type="email" required name='user_email' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                    </div>

                    <div className="mt-8">
                      <label className="">Phone Number</label><br />
                      <input type="number" required name='user_phone_number' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                    </div>

                    <div className="mt-8">
                      <label>Subject</label><br />
                      <input name='user_subject' required className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                    </div>

                    <div className="mt-8">
                      <label>Comment or Message</label><br />
                      <textarea row="10" required name='message' placeholder='Message' className="border   rounded-lg  p-2 mt-2 mb-2 w-full"></textarea>
                    </div>
                    <div className="App">
                      <input
                        type="file" 
                        onChange={(event) => {
                          setImageUpload(event.target.files[0]);
                        }}
                      />
                    </div>
                    <div className=" my-14 ">
                       <ReCAPTCHA sitekey="6LdBo5YnAAAAAIa_nmquSmJH_qV_F20uy6LEwka0" onChange={handleCaptchaChange} />

                      <a href="" target="_blank">
                        <button type='submit'  className=" rounded-lg  bg-gray-200  hover:bg-black hover:text-white py-3 px-4">Submit
                          your CV
                        </button>
                      </a>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </section>

      </div>


















    </div>
  )
}

export default Contactform

